// third-party
import { createSlice } from '@reduxjs/toolkit';
// import { FormikValues } from 'formik';

// project imports
// import axios from 'utils/axios';
import axios from 'axios';
import { dispatch } from '../index';
import { API_HOST } from 'config';

// types
import { DefaultRootStateProps } from 'types';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['consent'] = {
    error: null,
    thumbnails: [],
    consents: [],
    loading: false,
};

const slice = createSlice({
  name: 'consent',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET THUMBNAIL
    getThumbnailSuccess(state, action) {
      state.thumbnails = action.payload;
    },

    // GET CONSENT
    getConsentSuccess(state, action) {
      state.consents = action.payload;
    },

    // SET LOADING
    setLoading(state, action) {
      state.loading = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function uploadConsents (customerId: number, consents: any[]) {
  return async () => {
    try {
      dispatch(slice.actions.setLoading(true));
      await axios.post(`${API_HOST}/consent/${customerId}`, consents);
      dispatch(slice.actions.setLoading(false));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setLoading(false));
    }
  };
}

export function getConsents (customerId: number, original?: boolean) {
  return async () => {
    try {
      dispatch(slice.actions.setLoading(true));
      const url = !!original ? `${API_HOST}/consent/${customerId}?type=original` : `${API_HOST}/consent/${customerId}`
      const response = await axios.get(url);
      if (!!original) {
        dispatch(slice.actions.getConsentSuccess(response.data));
      } else {
        dispatch(slice.actions.getThumbnailSuccess(response.data));
      }
      dispatch(slice.actions.setLoading(false));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteConsent (customerId: string, name: string) {
  return async () => {
    try {
      dispatch(slice.actions.setLoading(true));
      await axios.delete(`${API_HOST}/consent/${customerId}`, {
        params: {
          name
        }
      });
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
