// third-party
import { createSlice } from "@reduxjs/toolkit";
import { FormikValues } from "formik";
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  isAfter,
  isBefore,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

// project imports
// import axios from 'utils/axios';
import axios from "axios";
import { dispatch } from "../index";
import { openSnackbar } from "store/slices/snackbar";
import { API_HOST, TIMEZONE } from "config";

// types
import { DefaultRootStateProps } from "types";

// color variants
import value from "assets/scss/_themes-vars.module.scss";

// ----------------------------------------------------------------------
const snackbar = (message: string) => ({
  open: true,
  message,
  variant: "alert",
  close: false,
  transition: "SlideDown",
  anchorOrigin: { vertical: "top", horizontal: "center" },
});

const initialState: DefaultRootStateProps["calendar"] = {
  error: null,
  events: [],
  date: new Date(),
  startDate: new Date(),
  endDate: new Date(),
  view: "timeGridDay",
  selectedEvent: null,
  isCounting: true,
  reminders: [],
  recentAddedEvents: [],
  loading: false,
};

const slice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.events = action.payload;
    },

    // GET EVENT DETAILS
    getEventSuccess(state, action) {
      state.selectedEvent = action.payload;
    },

    // UPDATE DATE & VIEW
    updateDateViewSuccess(state, action) {
      state.date = action.payload.date;
      state.view = action.payload.view;
    },

    // UPDATE DATE RANGE
    updateDateRangeSuccess(state, action) {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },

    // TOGGLE COUNTDOWN
    setCountdownSuccess(state, action) {
      state.isCounting = action.payload;
    },

    // SET REMINDER
    setReminderSuccess(state, action) {
      state.reminders = action.payload;
    },

    // GET RECENT ADDED EVENTS
    getRecentAddedEventsSuccess(state, action) {
      state.recentAddedEvents = action.payload;
    },

    // SET LOADING
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

const getColor = (status: string, service: string) => {
  if (status.toLowerCase() === "cancelled") {
    return value.grey500; // grey
  } else if (status.toLowerCase() === "did not do") {
    return value.secondary200; // light purple
  } else if (status.toLowerCase() === "did not come") {
    return value.errorLight; // light pink
  } else if (status.toLowerCase() === "late") {
    return value.orangeDark; // orange
  } else if (service.toLowerCase() === "day off") {
    return value.warningDark; //  yellow
  } else if (service.toLowerCase() === "no bed") {
    return value.grey700; // grey
  } else if (service.toLowerCase() === "consultation") {
    return value.successDark; // green
  } else if (service.toLowerCase().includes("sun spots")) {
    return value.tealMain; // teal
  } else if (
    service.toLowerCase().includes("whitening") ||
    service.toLowerCase().includes("piqo4 tattoo removal") ||
    service.toLowerCase().includes("lips pigmentation") // Lips Pigmentation Reduction Laser PiQo4
  ) {
    return value.pinkMain; // pink
  } else if (
    service.toLowerCase().includes("laser hair removal") ||
    service.toLowerCase().includes("fotona dynamis pro laser treatment") ||
    service.toLowerCase().includes("abdomen tightsculpting")
  ) {
    return value.secondary1000; // dark purple
  }

  return value.primaryDark; // blue
};

// ----------------------------------------------------------------------

export function getEvents(
  startDate: string,
  endDate: string,
  staffId?: string
) {
  return async () => {
    try {
      const url = !!staffId
        ? `${API_HOST}/booking?startDate=${startDate}&endDate=${endDate}&staffId=${staffId}`
        : `${API_HOST}/booking?startDate=${startDate}&endDate=${endDate}`;
      const response = await axios.get(url);

      const bookings = response.data
        .filter((b: any) => b.status !== "Inactive")
        .map((b: any) => {
          b = {
            ...b,
            start: utcToZonedTime(b.startTime, TIMEZONE),
            end: utcToZonedTime(b.endTime, TIMEZONE),
            requestNote: b.requestNote || "",
            color: getColor(b.status, b.cartProduct.product.name),
            classNames: [b.status.replace(/\s+/g, '-').toLowerCase()],
            title: `<span class="time">${format(
              utcToZonedTime(b.startTime, TIMEZONE),
              "h:mmaaa"
            )} - ${format(
              utcToZonedTime(b.endTime, TIMEZONE),
              "h:mmaaa"
            )}</span>
                      <span class="customer">
                        ${b.customer.name}
                        ${
                          b.customer.medicalHistory.allowSocialMedia ===
                          "With eye"
                            ? "⭐️"
                            : ""
                        }
                        ${
                          b.customer.medicalHistory.allowSocialMedia ===
                          "Without eye"
                            ? "⭐️⭐️"
                            : ""
                        }
                      </span>
                      <span class="service">${
                        b.cartProduct.product?.name
                      }</span>
                      <span class="remark">${b.customer.remark}</span>
                      <span class="note">${b.requestNote}</span>`,
          };
          delete b.startTime;
          delete b.endTime;
          return b;
        });
      dispatch(slice.actions.getEventsSuccess(bookings));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEvent(id: number) {
  return async () => {
    try {
      if (!id) {
        dispatch(slice.actions.getEventSuccess(null));
      } else {
        const response = await axios.get(`${API_HOST}/booking/${id}`);
        dispatch(slice.actions.getEventSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addEvent(event: FormikValues) {
  return async () => {
    try {
      await axios.post(`${API_HOST}/booking`, event);
      dispatch(openSnackbar(snackbar("New booking added")));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateEvent(eventId: number, event: FormikValues) {
  return async () => {
    try {
      await axios.patch(`${API_HOST}/booking/${eventId}`, event);
      dispatch(openSnackbar(snackbar(`Booking (id: ${eventId}) updated`)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteEvent(eventId: number) {
  return async () => {
    try {
      await axios.delete(`${API_HOST}/booking/${eventId}`);
      dispatch(openSnackbar(snackbar(`Booking (id: ${eventId}) deleted`)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateDateView(date: Date, view: string) {
  return async () => {
    try {
      let startDate = date;
      let endDate = date;

      if (view === "timeGridDay") {
        startDate = date;
        endDate = date;
      } else if (view === "timeGridWeek" || view === "listWeek") {
        startDate = startOfWeek(date, { weekStartsOn: 1 });
        endDate = endOfWeek(date, { weekStartsOn: 1 });
      } else if (view === "dayGridMonth") {
        startDate = startOfMonth(date);
        endDate = endOfMonth(date);
      }
      dispatch(slice.actions.updateDateViewSuccess({ date, view }));
      dispatch(slice.actions.updateDateRangeSuccess({ startDate, endDate }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setCountdown(isPausing: boolean) {
  return async () => {
    try {
      dispatch(slice.actions.setCountdownSuccess(isPausing));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getReminder() {
  return async () => {
    try {
      const disabledReminderIds = JSON.parse(
        sessionStorage.getItem("disabled_reminder_ids") || "[]"
      );
      const response = await axios.get(
        `${API_HOST}/booking?status=Approved&startDate=${new Date().toISOString()}&endDate=${new Date().toISOString()}`
      );
      const reminders = response.data.filter((b: any) => {
        return (
          !disabledReminderIds.includes(b.id) &&
          isBefore(new Date(b.startTime), new Date()) &&
          isAfter(new Date(b.endTime), new Date()) &&
          (b.customer.medicalHistory?.allowSocialMedia?.toLowerCase() ===
            "with eye" ||
            b.customer.medicalHistory?.allowSocialMedia?.toLowerCase() ===
              "without eye")
        );
      });
      dispatch(slice.actions.setReminderSuccess(reminders));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRecentAddedEvents(
  createdAtFrom: string,
  createdAtTo: string,
) {
  return async () => {
    try {
      dispatch(slice.actions.setLoading(true));
      const url = `${API_HOST}/booking?createdAtFrom=${createdAtFrom}&createdAtTo=${createdAtTo}`;
      const response = await axios.get(url);

      const bookings = response.data
        .filter((b: any) => b.status !== "Inactive")
        .map((b: any) => {
          b = {
            ...b,
            serviceName: b.cartProduct.product.name,
            customerId: b.customer.id,
            customerName: b.customer.name,
            customerPhone: b.customer.phone,
            customerEmail: b.customer.email,
            start: utcToZonedTime(b.startTime, TIMEZONE),
            end: utcToZonedTime(b.endTime, TIMEZONE),
            requestNotes: b.requestNote || "",
          };
          delete b.startTime;
          delete b.endTime;
          return b;
        });
      dispatch(slice.actions.getRecentAddedEventsSuccess(bookings));
      dispatch(slice.actions.setLoading(false));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setLoading(false));
    }
  };
}